<template>
  <v-container id="chatWrapper" ref="chatWrapper" fluid style="padding: 0;">
    <div class="chat-container" ref="chatContainer" @scroll="onScroll">

      <message
        v-for="message in messagesParsed"
        :key="message.idMensaje"
        :message="message"
        @imageLoad="scrollToEnd"
        @reload="$emit('reload')"
      ></message>

      <v-fab-transition>
        <v-btn
          v-show="progress > 0.1"
          color="primary"
          fab
          dark
          small
          absolute
          bottom
          right
          @click.stop="scrollToEnd"
        >
          <v-icon>mdi-arrow-down-thick</v-icon>
        </v-btn>
      </v-fab-transition>

    </div>

    <message-input ref="messageInput" :idEstudio="idEstudio" @reload="$emit('reload')" />
  </v-container>
</template>

<script>
import pallete from "@/utils/colorPallete.js";
import 'viewerjs/dist/viewer.css'

export default {
  props: {
    messages: Array,
    idEstudio: Number | String,
  },
  components: {
    Message: () => import("@/components/Chat/Message.vue"),
    MessageInput: () => import("@/components/Chat/MessageInput.vue"),
  },
  data() {
    return {
      progress: 0,
    };
  },
  computed: {
    messagesParsed() {
      let map = {},
        roots = [],
        i;

      for (i = 0; i < this.messages.length; i += 1) {
        map[this.messages[i].idMensaje] = i; // initialize the map
        this.messages[i].children = []; // initialize the children
        this.messages[i].userColor =
          pallete[
            ((this.messages[i].idUsuario % pallete.length) + pallete.length) %
              pallete.length
          ];
      }

      this.messages.forEach((node) => {
        if (node.idParent && node.idParent != "0") {
          // if you have dangling branches check that map[node.parentId] exists
          node.extension = node.archivo.split('.')[ node.archivo.split('.').length - 1 ]
          this.messages[map[node.idParent]].children.push(node);
        } else {
          roots.push(node);
        }
      });

      return roots;
    },
  },
  mounted() {
    const dropzone = this.$refs.chatWrapper;

    dropzone.addEventListener("dragenter", (e) => {
      e.preventDefault();
      this.$refs.messageInput.message.showFiles = true;
    });

    // this.$nextTick(() => this.scrollToEnd())
  },
  methods: {
    scrollToEnd() {
      this.$nextTick(() => {
        var container = this.$el.querySelector(".chat-container");
        container.scrollTop = container.scrollHeight;
      });
    },
    scrollTo() {
      this.$nextTick(() => {
        let currentHeight = this.$refs.chatContainer.scrollHeight;
        let difference = currentHeight - this.totalChatHeight;
        var container = this.$el.querySelector(".chat-container");
        container.scrollTop = difference;
      });
    },
    onScroll() {
      const progress = Math.abs(
        this.$refs.chatContainer.scrollTop /
          (this.$refs.chatContainer.scrollHeight -
            this.$refs.chatContainer.clientHeight)
      );
      if (progress > 1) {
        this.progress = 1;
      } else if (progress < 0) {
        this.progress = 0;
      } else {
        this.progress = progress;
      }
    },
  },
  // updated() {
  //   this.scrollToEnd()
  // }
};
</script>

<style>
.chat-container {
  box-sizing: border-box;
  min-height: 200px;
  max-height: 600px;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;
  background-color: #e4e4e4;
}
.theme--dark .chat-container {
  background-color: #272727;
}
</style>
